<template>
  <div class="setLive" style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
      <div class="setLive-table">
        <div class="setLive-table-content">
          <div>
            <a-button
                @click="addBtn"
                style="width: 100px"
                type="primary">新增</a-button>
          </div>
          <div style="margin-top: 15px">
            <a-table
                :pagination = false
                :rowKey='record=>record.id'
                style="width: 98%;margin: auto"
                :columns="content_LiveBroadcast_columns"
                :data-source="content_LiveBroadcast_data">
              <span slot="system" slot-scope="text, record">
                <a  @click="content_LiveBroadcast_rest_Btn(record)">修改</a>
                <a-popconfirm
                    style="margin-left: 8px;margin-right: 8px"
                    title="是否确认删除?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="content_LiveBroadcast_data_delBtn(record.id)"
                >
                  <a>删除</a>
                </a-popconfirm>
                 <a-dropdown :trigger="['click']">
                  <a class="ant-dropdown-link" @click="e => e.preventDefault()">更多<a-icon type="down" /></a>
                  <a-menu slot="overlay">
                    <a-menu-item @click="dealBtn(record.protocolIsOpen,record.id,record)" key="0">
                      协议
                    </a-menu-item>
                    <!--                    <a-menu-item @click="RCYL_duration_switch(record)" key="1">-->
                    <!--                      时长-->
                    <!--                    </a-menu-item>-->
                    <a-menu-item key="1">
                      <a-popover
                          :overlayStyle="{ width: '250px' }"
                          :getPopupContainer=" triggerNode => {
                            return triggerNode.parentNode}">
                        <template slot="content">
                          <p>
                            <a-icon style="color: orange;margin-right: 5px" type="exclamation-circle" />
                            <span v-if="!record.isGallery">云展厅未开启，是否确认开启?</span>
                            <span v-else>云展厅已开启，是否确认关闭?</span>
                          </p>
                          <a-button style="margin-left: 135px" :size="size">否</a-button>
                          <a-button
                              @click="content_CloudHall_data_delBtn(record.id,record.isGallery===1?0:1)"
                              style="margin-left: 10px" :size="size" type="primary">是</a-button>
                        </template>
                          <span>云展厅</span>
                        </a-popover>
                    </a-menu-item>
                    <!--                    <a-menu-item @click="Enterprise_zone_Switch(record)" key="3">-->
                    <!--                      企业鸣谢-->
                    <!--                    </a-menu-item>-->
                    <a-menu-item @click="opening_figure_Switch(record)" key="2">
                      开场图
                    </a-menu-item>
                    <a-menu-item @click="functionBtn(record)" key="3">
                      功能设置
                    </a-menu-item>
                    <a-menu-item @click="suspensionBtn(record)" key="4">
                      悬浮功能
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
              <!--时间-->
              <span slot="startTime" slot-scope="text, record">
                {{(record.startTime).slice(11,19)}}~{{(record.endTime).slice(11,19)}}
              </span>
              <!--查看地址-->
              <span slot="address" slot-scope="text, record">
                <a @click="address_list_Btn(record)">查看地址</a>
              </span>
<!--              &lt;!&ndash;时长&ndash;&gt;-->
<!--              <span slot="timeTime" slot-scope="text, record">-->
<!--                <a @click="RCYL_duration_switch(record)"-->
<!--                   v-if="record.durationIsOpen===1">已开启</a>-->
<!--                <a  @click="RCYL_duration_switch(record)"-->
<!--                    v-else>已关闭</a>-->
<!--              </span>-->
              <!--回放-->
              <span slot="replayIsOpen" slot-scope="text, record">
                 <a @click="replay_is_open_switch(record)"
                    v-if="record.replayIsOpen===1">已开启</a>
                <a @click="replay_is_open_switch(record)" v-else>已关闭</a>
              </span>
              <!--登陆方式-->
              <span slot="onWay" slot-scope="text, record">
             <!--              登录类型phone_code:手机号验证码   name_hospital:姓名医院-->
                   <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'phone_code'">
                     手机号验证码
                   </a>
                   <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'name_hospital'">
                     医院姓名
                   </a>
                   <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'none_login'">
                     无登录
                   </a>
              </span>
<!--              &lt;!&ndash;云展厅 &ndash;&gt;-->
<!--              <span slot="CloudHall" slot-scope="text, record">-->
<!--                <a-popconfirm-->
<!--                    style="margin-left: 8px"-->
<!--                    title="是否确认修改?"-->
<!--                    ok-text="Yes"-->
<!--                    cancel-text="No"-->
<!--                    @confirm="content_CloudHall_data_delBtn(record.id,record.isGallery===1?0:1)"-->
<!--                >-->
<!--                  <a v-if="record.isGallery===1">已开启</a>-->
<!--                  <a v-else>已关闭</a>-->
<!--                </a-popconfirm>-->
<!--              </span>-->
              <!--企业专区 -->
<!--              <span slot="EnterpriseZone" slot-scope="text, record">-->
<!--                 <a @click="Enterprise_zone_Switch(record)"-->
<!--                    v-if="record.isEnterprise===1">已开启</a>-->
<!--                <a @click="Enterprise_zone_Switch(record)"-->
<!--                   v-else>已关闭</a>-->
<!--              </span>-->
<!--              &lt;!&ndash;开场图 &ndash;&gt;-->
<!--              <span slot="openingFigure" slot-scope="text, record">-->
<!--                 <a @click="opening_figure_Switch(record)"-->
<!--                    v-if="record.isBeginImage===1">已开启</a>-->
<!--                <a @click="opening_figure_Switch(record)"-->
<!--                   v-else>已关闭</a>-->
<!--              </span>-->
            </a-table>
            <div style="width: 100%;height: 50px;">
              <div style="float: right;margin-right: 20px;margin-top: 10px">
                <a-pagination
                    show-quick-jumper
                    :total="content_LiveBroadcast_data_total"
                    @change="Tuition_LiveBroadcast_data_pagination_Change" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部   -->
      <div style="height: 60px"></div>
      <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
        <div
            style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
          <a-button @click="returnBtn">返回上一层</a-button>
        </div>
      </div>
    </a-spin>
<!----------------------------------------对话框-------------------------------------------->
    <a-modal
        @cancel="liveRoom_close"
        width="600px"
        v-model="liveRoom_visible"
        title="新增">
      <a-form-model
          ref="content_ByAdvertising"
          :model="content_LiveBroadcast_form"
          :rules="content_LiveBroadcast_rules"
          :label-col="labelCol_add"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="封面图" prop="url">
          <div style="display: flex;width: 400px;">
              <div class="uploadImg" >
                <input
                    id="upload_image_liveDate"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_LiveBroadcast_image"
                    ref="content_LiveBroadcast_imageUrl" />
              </div>
              <div
                  class="upload_deyails">
                <img
                    v-if="content_LiveBroadcast_form.url"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="content_LiveBroadcast_form.url"
                />
              </div>
            </div>
          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>
        </a-form-model-item>
        <a-form-model-item label="详情海报" prop="urlHai">
          <div style="display: flex;width: 400px;">
              <div class="uploadImg" >
                <input
                    id="upload_img_liveDte_url"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_LiveBroadcast_urlHaiImage"
                    ref="content_LiveBroadcastUrl" />
              </div>
              <div
                  class="upload_LiveBroadcast">
                <img
                    v-if="content_LiveBroadcast_form.urlHai"
                    alt=""
                    style="width: 100%;height:auto"
                    :src="content_LiveBroadcast_form.urlHai"
                />
              </div>
            </div>
          <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-input
              style="width: 71%"
              v-model="content_LiveBroadcast_form.title"
              placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="直播时间" prop="dateVal">
          <a-date-picker
              v-model="content_LiveBroadcast_form.dateVal"
              :allowClear="false"
              placeholder="请选择"
              @change="content_LiveBroadcast_liveTimeChange" />
        </a-form-model-item>
        <a-form-model-item label="直播开始时间" prop="dateStart">
<!--          v-model="LiveBroadcast_start" content_LiveBroadcast_form.dateStart   -->
          <a-time-picker
              v-model="LiveBroadcast_start"
              :allowClear="false"
              placeholder="请选择"
              @change="content_LiveBroadcast_startChange" />
        </a-form-model-item>
        <a-form-model-item label="直播结束时间" prop="dateEnd">
<!--          LiveBroadcast_end  content_LiveBroadcast_form.dateEnd -->
          <a-time-picker
              v-model="LiveBroadcast_end"
              :allowClear="false"
              placeholder="请选择"
              @change="content_LiveBroadcast_endChange" />
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="liveRoom_close">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="liveRoom_Ok">
          确认
        </a-button>
      </div>
    </a-modal>
    <a-modal
        @cancel="rest_liveRoom_close"
        width="600px"
        v-model="rest_visiblePlayback"
        title="修改">
      <a-form-model
          ref="content_rest_visiblePlayback_ruleForm"
          :model="content_rest_visiblePlayback_form"
          :rules="content_rest_visiblePlayback_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="cover_url" label="封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_rest"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_rest_visiblePlayback_image"
                  ref="content_rest_visiblePlayback_one" />
            </div>
            <div
                class="upload_deyails">
              <img
                  v-if="content_rest_visiblePlayback_form.cover_url"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="content_rest_visiblePlayback_form.cover_url"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸 640*360，大小不超过500kb</div>
        </a-form-model-item>
        <a-form-model-item prop="desc_image_url" label="详情海报">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_rest_visiblePlayback_two"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_rest_visiblePlayback_two_image"
                  ref="content_rest_visiblePlayback_two" />
            </div>
            <div
                class="upload_LiveBroadcast">
              <img
                  v-if="content_rest_visiblePlayback_form.desc_image_url"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="content_rest_visiblePlayback_form.desc_image_url"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item  label="标题" prop="title">
          <a-input
              v-model="content_rest_visiblePlayback_form.title"
              placeholder="请输入"/>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="rest_liveRoom_close">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="onSubmit_resetFormPlayback">
          确认
        </a-button>
      </div>
    </a-modal>
    <!--  地址  -->
    <a-drawer
        title="地址"
        :width="450"
        :visible="visibleAdress"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormAddress"
    >
      <div style="width: 450px">
        <div>
          <div style="margin-left: 15px">
            推流: 服务器
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address1"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 30px">
              <a-icon @click="copySum(addressForm.address1)" type="copy" />
            </div>
          </div>

        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            推流: 串流密钥
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address2"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address2)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：rtmp
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address3"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address3)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：flv
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address4"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address4)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：m3u8
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address5"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 50px">
              <a-icon @click="copySum(addressForm.address5)" type="copy" />
            </div>
          </div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFormAddress">
          关闭
        </a-button>
      </div>
    </a-drawer>
    <!--时长-->
    <a-modal
        @cancel="()=>{ this.content_RCYL_duration_visible = false;
          this.durationForm.RCYL_duration_switch_isOpen_list = 0
          this.durationForm.duration_remark = ''
        }"
        width="400px"
        v-model="content_RCYL_duration_visible"
        title="时长修改">
      <div>
        <span>状态：</span>
        <a-switch
            v-model="durationForm.RCYL_duration_switch_isOpen_list === 1 ? true:false"
            checked-children="开"
            un-checked-children="关"
            @click="RCYL_duration_change_isOpen(durationForm.RCYL_duration_switch_isOpen_list === 1 ?0:1)"
            default-checked />
      </div>
      <div style="margin-top: 15px">
        <span>备注：</span>
        <a-input
            style="width: 300px"
            placeholder="请输入"
            v-model="durationForm.duration_remark"
        />
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="()=>{ this.content_RCYL_duration_visible = false
        this.durationForm.RCYL_duration_switch_isOpen_list = 0
          this.durationForm.duration_remark = ''
        }">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="content_RCYL_duration_Ok">
          确认
        </a-button>
      </div>
    </a-modal>
    <!--  回放  -->
    <a-drawer
        title="回放设置"
        :width="680"
        :visible="visiblePlayback"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormPlayback"
    >
      <div style="width: 600px">
        <div style="display: flex">
          <div style="color: black;font-size: 16px">状态:</div>
          <div style="margin-left: 15px">
            <a-switch
                :checked="PlaybackForm.replay_is_open !==1 ? false:true"
                @click="switchPlaybackChange(PlaybackForm.replay_is_open === 1? 0 : 1)"
                checked-children="开"
                un-checked-children="关"  />
          </div>
        </div>
        <div style="margin-top: 10px">
          <a-button type="primary" @click="addVideoBtn">新增</a-button>
        </div>
        <div>
          <a-table
              :pagination = false
              style="margin-top: 15px"
              :columns="columnsPlayback"
              :data-source="PlaybackTableData"
              :rowKey='record=>record.id'>
            <template
                v-for="col in ['name']"
                :slot="col"
                slot-scope="text, record, index"
            >
              <div :key="col">

                <div v-if="record.addIsShow">
                  <a-select
                      @popupScroll="popupScroll_addVideoBtn"
                      v-if="record.editable"
                      show-search
                      placeholder="输入视频标题关键字，从视频库选择"
                      option-filter-prop="children"
                      style="width: 200px"
                      @search="select_add_search"
                  ><a-select-option
                      v-for="role in videoList"
                      @click="select_add(role.title,role.id)"
                      :value="role.id">
                    {{role.title}}
                  </a-select-option>
                  </a-select>

                  <template  v-if="!record.editable">
                    <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                      {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                    </a>
                  </template>
                </div>

                <div v-else>
                  <a-input
                      v-if="record.editable"
                      style="margin: -5px 0"
                      :value="text"
                      @change="e => handleChange(e.target.value, record.id, col)"
                  />
                  <template  v-if="!record.editable">
                    <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                      {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                    </a>
                  </template>
                </div>

              </div>
            </template>
            <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
                <!--保存取消-->
                <span v-if="record.editable">
              <a @click="() => save(record.id,record)">保存</a>
              <a-popconfirm
                  ok-text="是"
                  cancel-text="否"
                  style="margin-left: 5px"
                  title="是否取消?"
                  @confirm="() => cancel(record.id,record)">
                <a>取消</a>
              </a-popconfirm>
            </span>
                <!--删除-->
                <span v-if="!record.editable">
                  <a @click="upOrDownBtn('up',record.id)">上移</a>
                  <a @click="upOrDownBtn('down',record.id)" style="margin-left: 6px;margin-right: 6px">下移</a>
                  <a-popconfirm
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="confirmDel(record.id)"><a>删除</a></a-popconfirm>
                </span>
              </div>
            </template>
          </a-table>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button type="primary" :style="{ marginRight: '8px' }" @click="resetFormPlayback">
          关闭
        </a-button>
      </div>
    </a-drawer>
    <!--企业鸣谢-->
    <a-modal
        @cancel="()=>{ this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''}"
        width="600px"
        v-model="content_Enterprise_zone_visible"
        title="企业鸣谢">
      <div>
        <span>状态：</span>
        <a-switch
            v-model="Enterprise_zoneForm.isOpen_list === 1 ? true:false"
            checked-children="开"
            un-checked-children="关"
            @click="RCYL_Enterprise_zone_change_isOpen(Enterprise_zoneForm.isOpen_list === 1 ?0:1)"
            default-checked />
      </div>
      <div style="margin-top: 15px;display: flex">
        <span style="width: 50px">图片：</span>
        <div>
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_Enterprise_zoneForm_img"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_RCYL_Enterprise_zone_image"
                  ref="content_RCYL_Enterprise_zone" />
            </div>
            <div
                class="upload_LiveBroadcast">
              <img
                  v-if="Enterprise_zoneForm.image"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="Enterprise_zoneForm.image"
              />
            </div>
          </div>
          <div style="color: #a1a1a1;margin-top: 5px">注:尺寸 宽640，大小不超过1M</div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="()=>{ this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''}">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="content_Enterprise_zone_Ok">
          确认
        </a-button>
      </div>
    </a-modal>
    <!--开场图-->
    <a-modal
        @cancel="()=>{ this.content_opening_figure_visible = false
        this.opening_figureForm.duration = ''
      this.opening_figureForm.isOpen_list = 0
      this.opening_figureForm.image = ''}"
        width="600px"
        v-model="content_opening_figure_visible"
        title="开场图">
      <a-form-model
          ref="content_opening_figure_ruleForm"
          :model="opening_figureForm"
          :rules="content_opening_figure_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="isOpen_list" label="状态">
          <a-switch
              v-model="opening_figureForm.isOpen_list === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="RCYL_opening_figure_change_isOpen(opening_figureForm.isOpen_list === 1 ?0:1)"
              default-checked />
        </a-form-model-item>
        <a-form-model-item prop="image" label="图片">
          <div>
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="up_img_figure"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_RCYL_opening_figure_image"
                    ref="content_RCYL_opening_figure" />
              </div>
              <div
                  class="upload_LiveBroadcast">
                <img
                    v-if="opening_figureForm.image"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="opening_figureForm.image"
                />
              </div>
            </div>
            <div style="color: #a1a1a1;margin-top: 5px">注:尺寸 宽640，大小不超过1M</div>
          </div>
        </a-form-model-item>


        <a-form-model-item prop="duration" label="时长">
          <a-input v-model="opening_figureForm.duration"/>
        </a-form-model-item>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="()=>{ this.content_opening_figure_visible = false
      this.opening_figureForm.isOpen_list = 0
      this.opening_figureForm.duration = ''
      this.opening_figureForm.image = ''}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_opening_figure_Ok">
            确认
          </a-button>
        </div>
      </a-form-model>
    </a-modal>
    <!--  协议设置  -->
    <a-drawer
        title="协议设置"
        :width="680"
        :visible="visibleDeal"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormDeal"
    >
      <div style="width: 600px">
        <div style="display: flex">
          <div style="color: black;font-size: 16px">状态:</div>
          <div style="margin-left: 15px">
            <a-switch
                :checked="dealForm.protocol_is_open !==1 ? false:true"
                @click="switchDealChange(dealForm.protocol_is_open === 1? 0 : 1)"
                checked-children="开"
                un-checked-children="关"  />
          </div>
        </div>
        <div style="margin-top: 20px">
          <div ref="editor" >
          </div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFormDeal">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmitDeal">
          确认
        </a-button>
      </div>
    </a-drawer>
    <!--    登陆方式-->
    <a-modal
        :width="500"
        v-model="visibleOnWay"
        title="登陆方式(设置登陆方式)"
        @ok="onWaySubmit">
      <a-form-model
          :model="onWayForm"
          :label-col="labelColLogin"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="选择登陆方式">
          <a-radio-group v-model="onWayForm.onWay" @change="selectLoginChange">
            <a-radio :style="radioStyle" value="phone_code">
              手机号验证码登录
            </a-radio>
            <a-radio :style="radioStyle" value="name_hospital">
              姓名医院登录（游客模式）
            </a-radio>
            <!--          <a-radio :style="radioStyle" value="none_login">-->
            <!--              无登录（游客模式）-->
            <!--          </a-radio>-->
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-show="typeLoginStatus" label="选择完善身份信息">
          <a-radio-group v-model="onWayForm.status" @change="selectStatusChange">
            <a-radio :style="radioStyle" :value='1'>
              是
            </a-radio>
            <a-radio :style="radioStyle" :value='0'>
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!--        <a-form-model-item v-show="!typeLoginStatus">-->
        <!--          <div style="width: 200px;margin-left: 32%">-->
        <!--            <span>显示科室：</span>-->
        <!--            <a-switch-->
        <!--                :checked="isOpen ===1 ? true:false"-->
        <!--                @click="officeIsOpen(isOpen === 1? 0 : 1)"-->
        <!--                checked-children="开"-->
        <!--                un-checked-children="关"  />-->
        <!--          </div>-->
        <!--          <div style="width: 200px;margin-left: 32%">-->
        <!--            <span>显示职称：</span>-->
        <!--            <a-switch-->
        <!--                :checked="isOpen ===1 ? true:false"-->
        <!--                @click="technicalIsOpen(isOpen === 1? 0 : 1)"-->
        <!--                checked-children="开"-->
        <!--                un-checked-children="关"  />-->
        <!--          </div>-->
        <!--          <div style="width: 200px;margin-left: 27%">-->
        <!--            <span>显示手机号：</span>-->
        <!--            <a-switch-->
        <!--                :checked="isOpen ===1 ? true:false"-->
        <!--                @click="telIsOpen(isOpen === 1? 0 : 1)"-->
        <!--                checked-children="开"-->
        <!--                un-checked-children="关"  />-->
        <!--          </div>-->
        <!--        </a-form-model-item>-->
      </a-form-model>
    </a-modal>
    <!--  模块功能  -->
    <a-drawer
        title="功能设置"
        :width="650"
        :visible="visibleFunction"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFunction"
    >
      <div>
        <a-alert
            message="注意事项"
            type="info"
            show-icon
        >
          <template #description>
            <div>
              1、详情是直播间必须开启的功能模块，不能关闭
            </div>
            <div>
              2、当直播间或回放的数量大于1，则自动显示目录，等于1则不显示
            </div>
            <div>
              3、功能的中英文名称用#间隔开，若不设置英文，则会在英文版本显示中文名称
            </div>
          </template>
        </a-alert>
        <a-table style="margin-top: 20px"
                 :columns="columnsFunction"
                 :pagination="false"
                 :rowKey='record=>record.id'
                 :data-source="dataFunction" bordered>
          <template slot="set" slot-scope="text, record">
            <a @click="Enterprise_zone_Switch()" v-if="record.title === '企业专区'">设置</a>
            <a @click="RCYL_duration_switch()" v-if="record.title === '时长'">设置</a>
            <span v-else></span>
          </template>
          <template slot="name" slot-scope="text, record">
            <a-input v-model="record.name"/>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-switch
                v-if="record.title === '讨论' || record.title === '云展厅'"
                v-model="record.isOpen"
                checked-children="开"
                un-checked-children="关"
                default-checked />
          </template>
        </a-table>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFunction">
          取消
        </a-button>
        <a-button type="primary" @click="saveFunction">
          保存
        </a-button>
      </div>
    </a-drawer>
    <!--悬浮功能 -->
    <a-modal
        @cancel="suspensionClose"
        width="500px"
        v-model="suspensionVisible"
        title="悬浮功能">
      <a-form-model
          ref="suspension"
          :model="suspensionForm"
          :rules="suspensionFormRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="状态">
          <a-switch
              v-model="suspensionForm.isopen === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="suspension_change_isOpen(suspensionForm.isopen === 1 ?0:1)"
              default-checked />
        </a-form-model-item>
        <a-form-model-item prop="icon" label="图标">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="suspensionForm_img"
                  ref="suspensionForm_img_ref" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="suspensionForm.icon"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="suspensionForm.icon"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="跳转链接" prop="url">
          <a-input
              v-model="suspensionForm.url"
              placeholder="请输入"
          />
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="suspensionClose">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="suspensionOk">
          确认
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {delSetLiveList, getOneLiveList} from "@/service/liveMeet_api";
import {
  get_Playback_list,
  get_video_list,
  getLiveBroadcast, post_video_list,
  put_Playback_list,
  put_RCYL_isOpen
} from "@/service/MedicalConference";
import {update} from "@/utils/update";
import {postCaseLive} from "@/service/live_api";
import {putSetLive_xieyi, putTitle} from "@/service/liveMeet_api2";
import E from 'wangeditor'
import {
  get_function_list,
  get_Playback_list_yun, post_video_list_h,
  put_function_list,
  put_Playback_UpOrDown
} from "@/service/MedicalConference_y";
const columnsPlayback = [
  {
    title: '视频Id',
    dataIndex: 'id',
    width: '15%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '标题',
    dataIndex: 'name',
    width: '25%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '时长',
    dataIndex: 'duration',
    width: '25%',
    scopedSlots: { customRender: 'duration' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const PlaybackTableData =[];
export default {
  data(){
    return{
      labelColLogin: { span: 8},
      typeLoginStatus:true,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      editor:null,
      columnsPlayback,
      PlaybackTableData,
      spinning:false,
      visibleAdress:false,
      content_RCYL_duration_visible:false,
      rest_visiblePlayback:false,
      //回放
      visiblePlayback:false,
      //企业专区
      content_Enterprise_zone_visible:false,
      replay_is_open_switch_id:'',
      addressForm:{
        address1:"",
        address2:"",
        address3:"",
        address4:"",
        address5:"",
      },
      content_LiveBroadcast_columns:[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width:'8%',
        },{
          title: '日程名称',
          dataIndex: 'title',
          key: 'title',
          width:'18%',
          ellipsis: true,
        },
        {
          title: '日期',
          dataIndex: 'liveDate',
          key: 'liveDate',
          width:'12%',
        },
        {
          title: '直播时间',
          dataIndex: 'startTime',
          key: 'startTime',
          width:'15%',
          scopedSlots: { customRender: 'startTime' },
        },
        {
          title: '地址',
          scopedSlots: { customRender: 'address' },
        },
        // {
        //   title: '协议',
        //   scopedSlots: { customRender: 'agreement' },
        // },
        // {
        //   title: '时长',
        //   dataIndex: 'durationIsOpen',
        //   key: 'durationIsOpen',
        //   scopedSlots: { customRender: 'timeTime' },
        // },
        {
          title: '回放',
          dataIndex: 'replayIsOpen',
          key: 'replayIsOpen',
          scopedSlots: { customRender: 'replayIsOpen' },
        },
        {
          title: '登录方式',
          scopedSlots: { customRender: 'onWay' },
        },
        // {
        //   title: '云展厅',
        //   dataIndex: 'isGallery',
        //   scopedSlots: { customRender: 'CloudHall' },
        // },
        // {
        //   title: '企业鸣谢',
        //   dataIndex: 'isEnterprise',
        //   scopedSlots: { customRender: 'EnterpriseZone' },
        // },
        // {
        //   title: '开场图',
        //   dataIndex: 'isBeginImage',
        //   scopedSlots: { customRender: 'openingFigure' },
        // },
        {
          title: '操作',
          dataIndex: 'system',
          key: 'system',
          width:'15%',
          scopedSlots: { customRender: 'system' },
        },


      ],
      content_LiveBroadcast_data:[],
      content_LiveBroadcast_data_one:[],
      content_LiveBroadcast_data_total:0,
      durationForm:{
        RCYL_duration_switch_isOpen_list:0,
        duration_remark:'',
      },
      RCYL_duration_switch_isOpen_id:'',
      PlaybackForm:{
        replay_is_open:0,
      },
      RCYL_Enterprise_zone_isOpen_id:'',
      Enterprise_zoneForm:{
        isOpen_list:0,
        image:'',
      },
      //开场图
      content_opening_figure_visible:false,
      opening_figureForm:{
        isOpen_list:0,
        image:"",
        duration:"",
      },
      opening_figure_id:'',
      LiveBroadcast_list_page_no:1,
      liveRoom_visible:false,
      content_LiveBroadcast_form:{
        url:'',
        title:'',
        dateStart:'',
        urlHai:'',
        dateVal:'',
        dateEnd:'',
      },
      content_LiveBroadcast_rules:{
        url: [{ required: true, message: '请选择', trigger: 'change' }],
        urlHai: [{ required: true, message: '请选择', trigger: 'change' }],
        dateVal: [{ required: true, message: '请选择', trigger: 'change' }],
        dateStart: [{ required: true, message: '请选择', trigger: 'change' }],
        dateEnd: [{ required: true, message: '请选择', trigger: 'change' }],
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      labelCol: { span: 4},
      labelCol_add: { span: 5},
      wrapperCol: { span: 14 },
      LiveBroadcast_start:null,
      LiveBroadcast_end:null,
      content_rest_visiblePlayback_form:{
        title:'',
        desc_image_url:'',
        cover_url:'',
      },
      content_rest_visiblePlayback_rules:{
        desc_image_url: [{ required: true, message: '请上传', trigger: 'blur' }],
        cover_url: [{ required: true, message: '请上传', trigger: 'blur' }],
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      content_LiveBroadcast_rest_Btn_id:'',

      cacheDataTwo:[],
      editingKey:'',
      content_PlaybackTableData_list_id:"",
      videoList:[],
      content_opening_figure_rules:{
        isOpen_list: [{ required: true, message: '请选择', trigger: 'blur' }],
        image: [{ required: true, message: '请上传', trigger: 'blur' }],
        duration: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      visibleDeal:false,
      dealForm:{
        protocol_is_open:0,
        protocol_content:'',
      },
      editor_menus:[
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        'fullscreen' // 全屏
      ],
      visibleOnWay:false,
      onWayForm:{
        onWay:'',
        status:0,
      },
      size:'small',
      dealId:null,
      visibleFunction:false,
      columnsFunction:[
        {
          title: '功能',
          dataIndex: 'title',
        },
        {
          title: '名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '设置',
          scopedSlots: { customRender: 'set' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],

      dataFunction:[],
      functionList:{},
      functionId:null,
      addVideoBtn_select_page_no:1,
      selectTitle:'',
      suspensionId:null,
      suspensionVisible:false,
      suspensionForm:{
        isopen:0,
        url:'',
        icon:'',
      },
      suspensionFormRules: {
        url: [{ required: true, message: '请输入', trigger: 'blur' }],
        icon: [{ required: true, message: '请上传', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.getLiveBroadcast_list()
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"直播")
  },
  methods:{
    //获取列表
    async getLiveBroadcast_list(page_no) {
      this.spinning = true
      const response = await getLiveBroadcast(this.$route.query.id,page_no)
      if (response.code === 0) {
        this.content_LiveBroadcast_data = response.data.rows
        this.content_LiveBroadcast_data_total = response.data.count
      } else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //获取单条数据
    async getLiveBroadcast_list_one(id) {
      this.spinning = true
      const response = await getOneLiveList(id)
      if (response.code === 0) {
        this.content_LiveBroadcast_data_one = response.data
      } else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //修改
    async content_LiveBroadcast_rest_Btn(row) {
      await this.getLiveBroadcast_list_one(row.id)
      let list = this.content_LiveBroadcast_data_one
      this.content_LiveBroadcast_rest_Btn_id = row.id
      //赋值
      let form = this.content_rest_visiblePlayback_form
      form.title = row.title
      form.desc_image_url = list.descImageUrl
      form.cover_url = list.coverUrl
      this.rest_visiblePlayback = true
    },
    //删除
    async content_LiveBroadcast_data_delBtn(id) {
      const response = await delSetLiveList(id)
      if (response.code === 0) {
        this.$message.success("删除成功！")
        //  刷新列表
        await this.getLiveBroadcast_list(this.LiveBroadcast_list_page_no)
      } else {
        this.$message.warning("失败！" + response.message)
      }
    },
    //查看地址
    async address_list_Btn(list) {
      await this.getLiveBroadcast_list_one(list.id)
      let row = this.content_LiveBroadcast_data_one
      this.visibleAdress = true
      let form = this.addressForm
      form.address1 = row.obsPushIp
      form.address2 = row.obsPushName
      form.address3 = row.pullRtmpIp
      form.address4 = row.pullFlvIp
      form.address5 = row.pullM3u8Ip
    },
    //时长
    RCYL_duration_switch(){
      let row = this.functionList
      //接口中的是否打开
      this.durationForm.RCYL_duration_switch_isOpen_list = parseInt(row.durationIsOpen)
      this.durationForm.duration_remark = row.durationRemark
      this.RCYL_duration_switch_isOpen_id = row.id
      this.content_RCYL_duration_visible = true
    },
    //点击回放对话框
    replay_is_open_switch(row) {
      this.PlaybackForm.replay_is_open = row.replayIsOpen
      this.replay_is_open_switch_id = row.id
      this.get_content_PlaybackTableData_list(row.id)
      this.visiblePlayback = true

    },
    //云展厅
    content_CloudHall_data_delBtn(id,isOpen){
      let data = {
        "isGallery":isOpen
      }
      this.rest_RCYL_isOpen(id,data)
    },
    //共用修改接口
    async rest_RCYL_isOpen(id,data) {
      const response = await put_RCYL_isOpen(id,data)
      if(response.code === 0){
        this.$message.success("修改成功！")
        //  刷新列表
        await this.getLiveBroadcast_list(this.LiveBroadcast_list_page_no)
      }else {
        this.$message.warning("失败！"+response.message)
      }
    },
    //企业专区
    async Enterprise_zone_Switch() {
      let row = this.functionList
      await this.getLiveBroadcast_list_one(row.id)
      let list = this.content_LiveBroadcast_data_one
      this.content_Enterprise_zone_visible = true
      this.RCYL_Enterprise_zone_isOpen_id = row.id
      let form = this.Enterprise_zoneForm
      form.isOpen_list = row.isEnterprise
      form.image = list.enterpriseImage
    },
    //开场图
    async opening_figure_Switch(row) {
      await this.getLiveBroadcast_list_one(row.id)
      let list = this.content_LiveBroadcast_data_one
      //赋值
      let form = this.opening_figureForm
      form.isOpen_list = row.isBeginImage
      form.duration = list.beginImageTime
      form.image = list.beginImageDetail

      this.opening_figure_id = row.id


      //  打开对话框
      this.content_opening_figure_visible = true
    },
    Tuition_LiveBroadcast_data_pagination_Change(page_no,page_size){
      this.LiveBroadcast_list_page_no = page_no
      this.getLiveBroadcast_list(page_no)
    },
    liveRoom_close(){
      this.liveRoom_visible = false
    },

    addBtn(){
      //默认情况下打开新增对话框显示父级会议的图片
      if(JSON.stringify(this.$store.state.convertionImg)!=='{}'){
        this.content_LiveBroadcast_form.url = this.$store.state.convertionImg.coverPics[0]
      }
      this.liveRoom_visible = true
    },
    returnBtn(){
      window.history.back()
    },
    async content_LiveBroadcast_image() {
      let inputDOM = this.$refs.content_LiveBroadcast_imageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.content_LiveBroadcast_form.url = image.data.url
          this.$message.success("上传成功")
          document.getElementById('upload_image_liveDate').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    async content_LiveBroadcast_urlHaiImage() {
      let inputDOM = this.$refs.content_LiveBroadcastUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.content_LiveBroadcast_form.urlHai = image.data.url
          this.$message.success("上传成功")
          document.getElementById('upload_img_liveDte_url').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    async content_rest_visiblePlayback_image() {
      let inputDOM = this.$refs.content_rest_visiblePlayback_one.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.content_rest_visiblePlayback_form.cover_url = image.data.url
          this.$message.success("上传成功")
          document.getElementById('up_img_rest').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500kb的图片！")
      }
    },
    async content_rest_visiblePlayback_two_image() {
      let inputDOM = this.$refs.content_rest_visiblePlayback_two.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.content_rest_visiblePlayback_form.desc_image_url = image.data.url
          this.$message.success("上传成功")
          document.getElementById('up_img_rest_visiblePlayback_two').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async content_RCYL_Enterprise_zone_image() {
      let inputDOM = this.$refs.content_RCYL_Enterprise_zone.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.Enterprise_zoneForm.image = image.data.url
          this.$message.success("上传成功")
          document.getElementById('up_img_Enterprise_zoneForm_img').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    //开场图
    async content_RCYL_opening_figure_image() {
      let inputDOM = this.$refs.content_RCYL_opening_figure.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.opening_figureForm.image = image.data.url
          this.$message.success("上传成功")
          document.getElementById('up_img_figure').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    content_LiveBroadcast_startChange(time, timeString){
      this.content_LiveBroadcast_form.dateStart = timeString
    },
    content_LiveBroadcast_liveTimeChange(time, timeString){
      this.content_LiveBroadcast_form.dateVal = timeString
    },
    content_LiveBroadcast_endChange(time, timeString){
      this.content_LiveBroadcast_form.dateEnd = timeString
    },
    liveRoom_Ok() {
      this.$refs.content_ByAdvertising.validate(async valid => {
        if (valid) {
          let form = this.content_LiveBroadcast_form
          let data = {
            convention_id: this.$route.query.id,
            title: form.title,
            cover_url: form.url,
            desc_image_url: form.urlHai,
            live_date: form.dateVal,
            start_time: form.dateVal + ' ' + form.dateStart,
            end_time: form.dateVal + ' ' + form.dateEnd,
          }
          const response = await postCaseLive(data)
          if(response.code === 0){
            this.$message.success("添加成功！")
            //  清空列表
             this.$refs.content_ByAdvertising.resetFields()
            this.LiveBroadcast_end = null
            this.LiveBroadcast_start = null
             this.liveRoom_visible = false
            //  刷新列表
              await this.getLiveBroadcast_list()
          }else {
            this.$message.warning("失败！"+response.message)
          }
        } else {
          return false;
        }
      })
    },
    rest_liveRoom_close(){
      this.rest_visiblePlayback = false
    },
    //回放
    resetFormPlayback(){
      this.visiblePlayback=false
      this.PlaybackTableData = []
      this.videoList = []
    },
    onSubmit_resetFormPlayback(){
      this.$refs.content_rest_visiblePlayback_ruleForm.validate(valid => {
        if (valid) {
          let form = this.content_rest_visiblePlayback_form
          let id = this.content_LiveBroadcast_rest_Btn_id
          let data = {
            title:form.title,
            descImageUrl:form.desc_image_url,
            coverUrl:form.cover_url,
          }
          this.rest_RCYL_isOpen(id,data)
          this.rest_visiblePlayback = false
          //  清空
          this.$refs.content_rest_visiblePlayback_ruleForm.resetFields();
        } else {
          this.$message.warning("有空~~")
          return false;
        }
      })
    },
    resetFormAddress(){
      this.visibleAdress = false
    },
    copySum (item) {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = item;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('success!')
      //移除input标签
      document.body.removeChild(input)
    },
    content_RCYL_duration_Ok(){
      let form = this.durationForm
      let data = {
        "durationIsOpen":form.RCYL_duration_switch_isOpen_list,
        "durationRemark":form.duration_remark
      }
      let id = this.RCYL_duration_switch_isOpen_id
      this.rest_RCYL_isOpen(id,data)
      this.content_RCYL_duration_visible = false
      this.durationForm.RCYL_duration_switch_isOpen_list = 0
      this.durationForm.duration_remark = ''
    },
    //时长
    RCYL_duration_change_isOpen(isOpen){
      this.durationForm.RCYL_duration_switch_isOpen_list = isOpen
    },
    switchPlaybackChange(is_open){
      this.PlaybackForm.replay_is_open = is_open
      let data = {
        replayIsOpen:is_open
      }
      let id = this.replay_is_open_switch_id
      this.rest_RCYL_isOpen(id,data)
    },
    async addVideoList(page_no,value) {
      let data = {
        page_no:page_no,
        title:value,
      }
      const response = await get_video_list(data)
      if (response.code === 0) {
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.videoList.push({
            duration: list[i].duration,
            id: list[i].id,
            remark: list[i].remark,
            title: list[i].title,
          })
        }
      } else {
        this.$message.warning("失败"+response.message)
      }
    },
    addVideoBtn() {
      //获取视频库列表
      this.addVideoList()
      this.PlaybackTableData.push({
        name: '',
        duration: '',
        editable: 1,
        addIsShow:1
      })
      // this.addIsShow = true
    },
    //滚动条监听
    popupScroll_addVideoBtn(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.addVideoBtn_select_page_no++
        this.addVideoList(this.addVideoBtn_select_page_no,this.selectTitle)
      }
    },
    select_add_search(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.videoList = []
      this.selectTitle = value
      this.addVideoList('',this.selectTitle)
    },
    select_add(title,id){
      this.videoListId = id
    },
    edit(id) {
      const newData = [...this.PlaybackTableData];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.PlaybackTableData = newData;
      }
    },
    handleChange(value, id, column) {
      const newData = [...this.PlaybackTableData];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.PlaybackTableData = newData;
      }
    },
    async putLive(id, data) {
      const response = await put_Playback_list(id, data)
      if (response.code === 0) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("出错"+ response.message)
      }
    },
    //获取列表
    async get_content_PlaybackTableData_list(id) {
      this.content_PlaybackTableData_list_id = id
      //获取列表
      // const response = await get_Playback_list(id,page_no)
      const response = await get_Playback_list_yun(id)
      if (response.code === 0) {
        let list = response.data
        //每次请求前清空
        this.PlaybackTableData = []
        for (let i = 0; i < list.length; i++) {
          this.PlaybackTableData.push({
            id: list[i].id,
            name: list[i].title,
            duration: list[i].duration,
            addIsShow:0,
          })
        }
        this.cacheDataTwo = this.PlaybackTableData.map(item => ({...item}));
      } else {
        this.$message.warning("出错" + response.message)
      }
    },
    //保存
    async save(id, row) {
      if (!row.addIsShow) {
        const newData = [...this.PlaybackTableData];
        const newCacheData = [...this.cacheDataTwo];
        const target = newData.find(item => id === item.id);
        const targetCache = newCacheData.find(item => id === item.id);
        if (target && targetCache) {
          delete target.editable;
          this.PlaybackTableData = newData;
          Object.assign(targetCache, target);
          this.cacheDataTwo = newCacheData;
        }
        this.editingKey = '';
        //修改标题
        const PlaybackTableData = {
          title: row.name
        }
        await this.putLive(id, PlaybackTableData)
      } else {
        //选择的视频库id,和列表id
        let videoManegeId = this.videoListId
        let streamId = this.replay_is_open_switch_id
        await this.postVideo(streamId, videoManegeId)
        row.editable = 0
        row.addIsShow = 0
        this.editingKey = '';
        //  刷新列表
        await this.get_content_PlaybackTableData_list(streamId)
      }
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.videoList = []
    },
    //添加视频库
    async postVideo(streamId,videoManegeId) {
      const response = await post_video_list(streamId,videoManegeId) //外包
      // const response = await post_video_list_h(streamId,videoManegeId) //韩笑测试
      if (response.code === 0) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("出错" + response.message)
      }
    },
    //取消
    cancel(id,row) {
      if(!row.addIsShow){
        const newData = [...this.PlaybackTableData];
        const target = newData.find(item => id === item.id);
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheDataTwo.find(item => id === item.id));
          delete target.editable;
          this.PlaybackTableData = newData;
        }
      }else {
        this.PlaybackTableData.pop()
      }
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.videoList = []
    },
    async confirmDel(id) {
      this.is_delete = 1
      const data = {
        is_delete: this.is_delete
      }
      const response = await putTitle(id,data)
      if(response.code === 0){
        this.$message.success("删除成功！")
        let streamId = this.replay_is_open_switch_id
        //  刷新列表
        await this.get_content_PlaybackTableData_list(streamId)
      }else {
        this.$message.warning("失败"+response.message)
      }
    },
    RCYL_Enterprise_zone_change_isOpen(isOpen){
      if(this.Enterprise_zoneForm.image !== '' && this.Enterprise_zoneForm.image !== null && this.Enterprise_zoneForm.image !== undefined){
        this.Enterprise_zoneForm.isOpen_list = isOpen
      }else {
        this.$message.warning("请先上传图片！")
      }
    },
    content_Enterprise_zone_Ok(){
      let form = this.Enterprise_zoneForm
      let data = {
        "isEnterprise":form.isOpen_list,
        "enterpriseImage":[form.image]
      }
      let id = this.RCYL_Enterprise_zone_isOpen_id
      this.rest_RCYL_isOpen(id,data)
      this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''
    },
    RCYL_opening_figure_change_isOpen(isOpen){
      if(this.opening_figureForm.image !== '' &&
          this.opening_figureForm.image !== null &&
          this.opening_figureForm.image !== undefined &&
          this.opening_figureForm.duration !== '' &&
          this.opening_figureForm.duration !== null &&
          this.opening_figureForm.duration !== undefined){
        this.opening_figureForm.isOpen_list = isOpen
      }else {
        this.$message.warning("请先上传图片并填写时长！")
      }
    },
    content_opening_figure_Ok(){
      this.$refs.content_opening_figure_ruleForm.validate(valid => {
        if (valid) {
          let form = this.opening_figureForm
          let data = {
            isBeginImage:form.isOpen_list,
            beginImageTime:form.duration,
            beginImageDetail:[form.image]
          }
          let id = this.opening_figure_id
          //修改
          this.rest_RCYL_isOpen(id,data)
          //清空
          this.opening_figureForm.isOpen_list = 0
          this.opening_figureForm.duration = ''
          this.opening_figureForm.image = ''
          this.content_opening_figure_visible = false
        } else {
          this.$message.warning("有空~~")
          return false;
        }
      });
    },
    resetFormDeal(){
      this.visibleDeal = false
    },
    switchDealChange(checked){
      this.dealForm.protocol_content = this.editor.txt.html()
      if(this.dealForm.protocol_content !== ''
          && this.dealForm.protocol_content !== undefined
          && this.dealForm.protocol_content !== null){
        this.dealForm.protocol_is_open = checked
      }else {
        this.$message.warning("请先输入内容后在开启！")
      }
    },
    onSubmitDeal(){
      this.dealForm.protocol_content = this.editor.txt.html()
      if(this.dealForm.protocol_content !== ''){
        this.modification_xieyi(this.dealId,this.dealForm)
        this.visibleDeal = false
        let form = this.searchForm
        //  刷新列表
        this.getLiveBroadcast_list(this.LiveBroadcast_list_page_no)
        this.dealForm.protocol_content = ''
      }else {
        this.$message.warning("请填写内容")
      }
    },
    //修改协议公用
    async modification_xieyi(id,data) {
      const response = await putSetLive_xieyi(id,data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        let form = this.searchForm
        //  刷新列表
        await this.getLiveBroadcast_list(this.LiveBroadcast_list_page_no)
      } else {
        this.$message.warning("修改失败" + response.message)
      }
    },
    //富文本编辑器
    async create_editor_config() {
      //等待DOM加载完毕
      const editor = new E(this.$refs.editor)
      this.editor = editor
      //去除图片视频上传
      editor.config.excludeMenus = [
        'image',
        'video'
      ]
      // 设置编辑区域高度为 500px
      editor.config.height = 200
      //创建编辑器
      await editor.create()
    },
    agreement_list_Btn(row){
      // if(response.data.intro){
      //   this.editor.txt.html(response.data.intro)
      // }
      this.visibleDeal = true
      //创建以及销毁富文本编辑器
      this.$nextTick(() => {
        if (this.editor === null) {
          this.create_editor_config()
        } else {
          this.editor.destroy()
          this.create_editor_config()
        }
      })
    },
    selectLoginChange(){
      let onWay = this.onWayForm.onWay
      if(onWay === 'phone_code'){
        this.typeLoginStatus = true
      }else {
        this.typeLoginStatus = false
      }
    },
    selectStatusChange(){
    },
    onWaySubmit(){
      const data = {
        loginType:this.onWayForm.onWay,
        isNeedPerfectInfo:this.onWayForm.status,
      }
      //修改
      this.rest_RCYL_isOpen(this.typeId,data)
      this.visibleOnWay = false
    },
    //登陆方式
    async onWayBtn(type, id) {
      //获取是否完善信息
      await this.getLiveBroadcast_list_one(id)
      this.visibleOnWay = true
      this.onWayForm.onWay = type
      this.typeId = id
      if (type === 'phone_code') {
        this.typeLoginStatus = true
        this.onWayForm.status = this.content_LiveBroadcast_data_one.isNeedPerfectInfo
      } else {
        this.typeLoginStatus = false
      }
    },
    //新增对话框富文本编辑器
    divRedio(){
      //初始化富文本编辑器
      const editor = new E(this.$refs.editor)
      this.editor = editor
      editor.config.height = 300
      //配置菜单栏
      editor.config.menus = this.editor_menus
      // //限制一次最多能传几张图片
      // editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
      editor.create()
    },
    // 协议设置
    dealBtn(isOpen,id,row){
      this.dealId = id
      this.dealForm.protocol_is_open = parseInt(isOpen)
      this.getLiveBroadcast_list_one(row.id)

      //等待dom元素更新
      this.$nextTick(() => {
        //判断文本编辑器是否创建，创建就销毁，防止重复创建
        if(this.editor === null){
          //初始化富文本编辑器
          this.divRedio()
        }else {
          //销毁
          this.editor.destroy()
          this.divRedio()
        }
      })
      this.visibleDeal = true
    },
    //根据id查询直播间功能设置
    async getFunction(id) {
      const response = await get_function_list(id)
      if(response.code === 0){
        let list = response.data
        this.dataFunction.push(
            {
              id:1,
              title:'详情',
              name:list[0].mkDetailName,
              isOpen:false,
            },{
              id:2,
              title:'目录',
              name:list[0].mkDirectoryName,
              isOpen:false,
            },{
              id:3,
              title:'讨论',
              name:list[0].mkDiscussName,
              isOpen:list[0].discussIsOpen === 1 ? true:false,
            },{
              id:4,
              title:'企业专区',
              name:list[0].mkCompanyName,
              // isOpen:list[0].isEnterprisel === 1 ? true:false,
              isOpen:false,
            },
            {
              id:5,
              title:'时长',
              name:list[0].mkDurationName,
              // isOpen:list[0].durationIsOpen === 1 ? true:false,
              isOpen:false,
            },
            {
              id:6,
              title:'云展厅',
              name:list[0].mkGalleryName,
              isOpen:list[0].isGallery === 1 ? true:false,
            },
        )
      }
    },
    async functionBtn(row) {
      this.functionId = row.id
      this.functionList = row
      await this.getFunction(row.id)
      this.visibleFunction = true
    },
    resetFunction(){
      this.dataFunction=[]
      this.visibleFunction = false
    },
    async saveFunction() {
      let row = this.dataFunction
      let data = {
        mkDetailName: row[0].name,
        mkDirectoryName: row[1].name,
        mkDiscussName: row[2].name,
        mkCompanyName: row[3].name,
        mkDurationName: row[4].name,
        mkGalleryName: row[5].name,

        discussIsOpen: row[2].isOpen === true ? 1 : 0,
        // isEnterprisel: row[3].isOpen === true ? 1 : 0,
        // durationIsOpen: row[4].isOpen === true ? 1 : 0,
        isGallery: row[5].isOpen === true ? 1 : 0,
      }
      const response = await put_f滑动unction_list(this.functionId, data)
      if (response.code === 0) {
        this.$message.success("保存成功~")
        this.visibleFunction = false
      } else {
        this.$message.success(response.message)
      }
      this.dataFunction = []
    },
    async upOrDownBtn(type, id) {
      const response = await put_Playback_UpOrDown(id,type)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        this.get_content_PlaybackTableData_list(this.replay_is_open_switch_id)
      } else {
        this.$message.success(response.message)
      }
    },
    suspensionBtn(row){
      this.suspensionId = row.id
      this.getSusContentList(row.id)
      this.suspensionVisible = true
    },
    async getSusContentList(id) {
      const response = await get_function_list(id)
      if(response.data.funXf !== null){
        if(response.code === 0){
          this.suspensionForm.url = response.data[0].funXf.url
          this.suspensionForm.isopen = response.data[0].funXf.isopen
          this.suspensionForm.icon = response.data[0].funXf.icon
        }
      }
    },
    suspensionClose(){
      this.suspensionVisible = false
    },
    suspensionOk(){
      this.$refs.suspension.validate(async valid => {
        if (valid) {
          let form = this.suspensionForm;
          let data = {
            funXf: {
              url: form.url,
              icon: form.icon,
              isopen: form.isopen
            }
          }
          const response = await put_function_list(this.suspensionId,data)
          if(response.code === 0){
            this.$message.success("设置成功~")
            this.suspensionVisible = false
          }else {
            this.$message.success(response.message)
          }
        } else {
          return false;
        }
      });
    },
    suspension_change_isOpen(isOpen){
      this.suspensionForm.isopen = isOpen
    },
    async suspensionForm_img() {
      let inputDOM = this.$refs.suspensionForm_img_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.suspensionForm.icon = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.setLive{
  .setLive-table{
    width: 100%;
    .setLive-table-content{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_deyails{
  border: #DDDDDD 2px dashed;
  width: 220px;
  height: 130px;
  margin-left: 20px;
  text-align: center;
}
.upload_poster{
  border: #DDDDDD 2px dashed;
  width: 160px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}

</style>
